footer {
  margin-top: 64px;
  padding: 0 0 15px 0;

  ul {
    list-style-type: none;
    text-align: right;

    li {
      display: inline-block;
    }
  }

  .company-details {
    background-color: #EA5707FF;
    background-image: linear-gradient(90deg, #EA5707FF 0%, #EE7F04FF 100%);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    padding: 34px 0;

    .wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 28px;

      @media screen and (min-width: $lg) {
        flex-direction: row;
      }
    }

    .location {
      background-image: url("../images/marker-pin.svg");
      background-position: 0 2px;
      background-repeat: no-repeat;
      padding-left: 35px;
    }

    .phone {
      background-image: url("../images/phone-pin.svg");
      background-position: 0 2px;
      background-repeat: no-repeat;
    }

    .email {
      background-image: url("../images/email-pin.svg");
      background-position: 0 3px;
      background-repeat: no-repeat;
    }

    a {
      color: #fff;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 20px;
      padding-left: 35px;
    }
  }

  .footer-menu {
    background-color: #f2f2f2;
    padding-bottom: 0;

    .wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .site-logo {
      margin: 18px 0 0 0;

      img {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }

    .menu li {
      margin-left: 45px;

      &.current-menu-item a {
        color: #EA5707;
        font-weight: 700;
      }

      a {
        color: #1F1F1F;
        font-size: 17px;
        font-weight: 500;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .cookies {
    padding: 15px 0 0 0;
    text-align: center;

    p {
      font-size: 14px;
    }

    a {
      color: #1F1F1F;
      font-weight: 600;

      &:hover {
        color: #EA5707;
      }
    }
  }
}
