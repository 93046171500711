header {
  .header-top {
    background-color: #f2f2f2;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 14px 0;

    .wrapper {
      display: flex;
      justify-content: space-between;
      position: relative;
    }

    .contact {
      display: flex;

      a, p {
        font-size: 14px;
        font-weight: 500;
      }

      p {
        background-image: url("../images/location-pin.png");
        background-position: 0 3px;
        background-repeat: no-repeat;
        padding-left: 24px;
        display: none;
        margin: 0;

        @media (min-width: $md) {
          display: block;
        }
      }

      a {
        color: #1F1F1F;
        background-image: url("../images/phone-pin.png");
        background-position: 0 3px;
        background-repeat: no-repeat;
        padding-left: 24px;
        margin-left: 20px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .lang-icon {
      border: 1px solid rgba(133, 134, 136, 0.5);
      border-radius: 5px;
      position: relative;

      .active {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        gap: 5px;
        padding: 0 11px;
      }

      ul {
        background-color: #fff;
        display: none;
        list-style-type: none;
        border: 1px solid rgba(133, 134, 136, 0.5);
        border-radius: 5px;
        margin: 0;
        padding: 0 11px;
        position: absolute;
        top: 35px;
        z-index: 1;

        li {
          line-height: 30px;

          &.active-lang a {
            font-weight: bold;
          }

          a {
            align-items: center;
            display: flex;
            gap: 5px;
          }
        }
      }
    }
  }

  .site-logo {
    margin: 19px 0 0 0;

    .custom-logo {
      max-width: 120px;

      @media screen and (min-width: $md) {
        max-height: 152px;
        max-width: 173px;
      }
    }
  }

  .wrapper-menu {
    display: flex;
    justify-content: space-between;
  }

  .menu {
    list-style-type: none;
    margin: 26px 0;

    @media screen and (min-width: $lg) {
      display: flex;
      padding-top: 0;
    }

    li {
      @media screen and (min-width: $lg) {
        margin-left: 45px;

        &:first-child {
          margin-left: 0;
        }

        &.current_page_item a {
          color: #EA5707;
        }
      }

      a {
        color: #1F1F1F;
        font-size: 17px;
        font-weight: bold;
        line-height: 20px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.nav-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: none;
  overflow: hidden;

  @media screen and (min-width: $lg) {
    display: block;
    position: relative;
    overflow: unset;
    margin-right: 20px;
    z-index: inherit;
  }

  nav {
    background-color: #fff;
    box-shadow: -5px 0px 17px -7px rgba(0,0,0,0.25);
    width: 350px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-top: 136px;

    @media screen and (min-width: $lg) {
      box-shadow: none;
      background-color: transparent;
      align-items: center;
      display: flex;
      justify-content: end;
      padding-top: 0;
      position: relative;
      width: unset;
    }
  }
}

#menu-button {
  background-color: #fff;
  background-image: url("../images/icon-burger.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 40px;
  margin-top: 10px;
  width: 57px;

  @media screen and (min-width: $lg) {
    display: none;
  }

  &.close {
    background-image: url("../images/icon-close.png");
    position: fixed;
    right: 12px;
    z-index: 1000;
  }

  &:hover {
    border-color: #fff;
  }
}