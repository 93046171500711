.about-content {
  display: flex;
  gap: 40px;
  flex-direction: column;
  margin-bottom: 40px;

  @media screen and (min-width: $lg) {
    flex-direction: row;
  }

  img {
    border-radius: 25px;
    display: block;
    margin: 0 auto;
    max-width: 100%;

    @media screen and (min-width: $lg) {
      max-width: inherit;
    }
  }

  .left {
    @media screen and (min-width: $lg) {
      text-align: right;
    }
  }
}

#stand-out {
  border-top: 2px solid #f2f2f2;
  margin: 23px 0 61px 0;
  padding: 51px 0 0 0;

  h2 {
    font-family: "Inter", sans-serif;
    font-weight: 900;
    font-size: 30px;
    margin-bottom: 39px;
    text-align: center;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 23px;
    list-style-type: none;
    padding: 0;

    @media screen and (min-width: $lg) {
      flex-direction: row;
    }

    li {
      background-color: #f2f2f2;
      border-radius: 25px;
      padding: 25px 0 27px 0;

      @media screen and (min-width: $lg) {
        flex: 1 1 0;
        width: 0;
      }

      .wrapper-stand {
        color: #f9f9f9;
        font-family: "Inter", sans-serif;
        font-weight: 900;
        font-size: 160px;
        line-height: 1;
        position: relative;
        text-align: center;

        .details {
          align-items: center;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 11px;
          width: 100%;
        }

        img {
          margin: 0 auto 12px auto;
        }

        p {
          color: #1f1f1f;
          font-weight: 500;

          b {
            display: block;
            font-weight: 900;
          }
        }
      }
    }
  }
}