.home-banner {
  margin: -50px 0 0 0;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: $lg) {
    margin: -90px auto 0 auto;
    overflow: visible;
  }

  .bg-wrapper {
    overflow: hidden;
  }

  .bg {
    display: block;
    margin: 0 auto;
  }

  .wrapper {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;

    @media screen and (min-width: $lg) {
      display: flex;
      justify-content: space-between;
    }

    .banner-img {
      display: none;
      margin-top: -90px;

      @media screen and (min-width: $lg) {
        display: block;
        margin-top: -110px;
        margin-left: 90px;
      }
    }
  }

  .banner-text {
    margin-top: 124px;
    text-align: center;

    @media screen and (min-width: $xs) {
      margin-top: 144px;
    }


    @media screen and (min-width: $lg) {
      margin-top: 54px;
      text-align: right;
    }

    @media screen and (min-width: $xl) {
      margin-top: 104px;
    }

    h1 {
      font-weight: 900;
      margin-top: 40px;
      text-shadow: 2px 2px rgba(31, 31, 31, 0.2);
      font-size: 42px;

      @media screen and (min-width: $xs) {
        font-size: 55px;
      }

      @media screen and (min-width: $lg) {
        font-size: 72px;
        line-height: 88px;
        margin-top: 0;
      }

      span {
        font-weight: 300;
      }
    }

    h2 {
      font-size: 26px;
      font-weight: 300;
      text-shadow: 2px 2px rgba(31, 31, 31, 0.2);

      span {
        font-weight: 900;
        color: rgb(238, 127, 4)
      }
    }
  }
}

.thin-banner {
  margin: -50px 0 0 0;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: $lg) {
    margin: -90px auto 0 auto;
    overflow: visible;
  }

  .bg-wrapper {
    overflow: hidden;
  }

  .bg {
    display: block;
    margin: 0 auto;
  }

  .wrapper {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;

    @media screen and (min-width: $lg) {
      display: flex;
      justify-content: space-between;
    }

    .banner-img {
      display: none;
      margin-top: -90px;

      @media screen and (min-width: $lg) {
        display: block;
        margin: -60px 0 0 160px;
      }
    }
  }

  .banner-text {
    margin-top: 120px;

    @media screen and (min-width: $lg) {
      margin-top: 60px;
    }

    h1 {
      font-size: 60px;
      font-weight: 900;
      margin-top: 40px;
      text-align: right;
      text-shadow: 2px 2px rgba(31, 31, 31, 0.2);

      @media screen and (min-width: $lg) {
        margin-top: 0;
      }

      &:after {
        background-color: #EA5707FF;
        background-image: linear-gradient(180deg, #EA5707FF 0%, #EE7F04FF 100%);
        content: '';
        display: inline-block;
        height: 50px;
        margin-bottom: -3px;
        margin-left: 18px;
        width: 14px;
      }

      span {
        font-weight: 300;
      }
    }
  }
}

.strap-bg {
  background-color: rgb(234, 87, 7);
  background-image: linear-gradient(90deg, rgb(234, 87, 7) 0%, rgb(238, 127, 4) 100%);
  height: 15px;
  width: 100%;
}