.achievements {
  display: flex;
  gap: 22px;
  list-style-type: none;
  padding-left: 0;
  flex-direction: column;

  @media screen and (min-width: $lg) {
    flex-direction: row;
  }

  li {
    background: rgb(242, 242, 242);
    border-radius: 25px;
    padding: 35px 0 36px 0;

    @media screen and (min-width: $lg) {
      flex: 1 1 0;
      width: 0;
    }

    .numbers {
      color: #fff;
      font-family: "Inter", sans-serif;
      font-weight: 900;
      font-size: 160px;
      line-height: 1;
      position: relative;
      text-align: center;

      @media screen and (min-width: $lg) {
        font-size: 130px;
      }

      @media screen and (min-width: $xl) {
        font-size: 160px;
      }

      .details {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        position: absolute;
        top: 0;
        text-align: center;
        width: 100%;

        img {
          margin-bottom: 8px;
        }

        p {
          color: #1F1F1F;
          font-size: 18px;
          font-weight: 500;

          span {
            display: block;
            font-weight: 900;
          }
        }
      }
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }
}

.home-text {
  text-align: center;
  margin: 40px 0 50px 0;

  @media screen and (min-width: $lg) {
    margin: 50px 97px 67px 97px;
  }

  p {
    font-weight: 500;
    font-size: 18px;

    &:first-child {
      margin: 0 70px 30px 70px;
    }

    span {
      color: #EA5707;
      font-family: "Inter", sans-serif;
      font-weight: 800;
      font-size: 18px;
    }
  }
}
