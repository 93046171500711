.error-404 {
  margin: 0;

  input[type=submit]:not(:hover):not(:active):not(.has-background) {
    background-color: #EA5707FF;
    background-image: linear-gradient(90deg, #EA5707FF 0%, #EE7F04FF 100%);
    border: none;
    border-radius: 45px;
  }

  input[type=submit]:hover {
    color: #fff;
    background-color: #EA5707FF;
    background-image: linear-gradient(90deg, #EA5707FF 0%, #EE7F04FF 100%);
    border: none;
    border-radius: 45px;
  }
}
