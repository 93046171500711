.contact-page {
  border-bottom: 2px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 60px;
  margin-bottom: 60px;

  @media screen and (min-width: $lg) {
    flex-direction: row;
  }

  .left {
    border-top: 2px solid #f2f2f2;

    @media screen and (min-width: $lg) {
      flex: 1 0 0;
      width: 0;
    }

    p {
      margin: 0;
    }

    .location {
      background-image: url("../images/icon-pin-contact.png");
      background-position: 6px 43px;
      background-repeat: no-repeat;
      border-bottom: 2px solid #f2f2f2;
      padding: 40px 0 42px 40px;
    }

    .phone {
      background-image: url("../images/icon-phone-contact.png");
      background-position: 6px 43px;
      background-repeat: no-repeat;
      padding: 40px 0 42px 40px;

      a {
        color: #1F1F1F;
        font-weight: 500;
        font-size: 18px;
      }

      b {
        display: block;
      }
    }

    .email {
      background-image: url("../images/icon-mail-contact.png");
      background-position: 6px 3px;
      background-repeat: no-repeat;
      border-bottom: 2px solid #f2f2f2;
      padding: 0 0 42px 40px;

      a {
        color: #1F1F1F;
        font-weight: 500;
        font-size: 18px;
      }

      b {
        display: block;
      }
    }
  }

  .right {
    @media screen and (min-width: $lg) {
      flex: 1 0 0;
      width: 0;
    }
  }
}

#contact-form {
  .field {
    margin-bottom: 15px;
  }

  label {
    color: #281615;
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 500;

    span {
      color: #FF1900;
    }
  }

  input {
    background-color: #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 25px;
    font-size: 18px;
    height: 53px;
    padding: 0 2%;
    width: 100%;
  }

  textarea {
    background-color: #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 25px;
    font-size: 18px;
    padding: 16px 2%;
  }

  .form-error {
    color: #FF515B;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
  }

  .message-error, .message-success {
    border-radius: 15px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 25px;
    padding: 20px;

    p {
      margin: 0;
      text-align: center;
    }
  }

  .message-error {
    background: #FF1900;
  }

  .message-success {
    background: #4CAD34;
  }
}

#contact-form_send {
  background-color: #EA5707FF;
  background-image: linear-gradient(90deg, #EA5707FF 0%, #EE7F04FF 100%);
  border: none;
  border-radius: 45px;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 900;
  padding: 12px 74px;
  text-transform: uppercase;
  width: 100%;

  @media screen and (min-width: $lg) {
    width: inherit;
  }
}
