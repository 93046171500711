.brands {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: 38px 0 30px 0;
  padding-left: 0;
  text-align: right;
  gap: 30px;

  @media screen and (min-width: $md) {
    flex-wrap: nowrap;
  }

  @media screen and (min-width: $lg) {
    display: block;
    gap: inherit;
  }

  li {
    display: inline-block;
    margin: 10px 0 0 0;

    @media screen and (min-width: $lg) {
      margin: 0 0 0 12px;
    }

    @media screen and (min-width: $xl) {
      margin: 0 0 0 52px;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
