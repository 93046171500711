body {
  font-family: "Inter", sans-serif;
}

p, a {
  font-family: "Inter", sans-serif;
}

a {
  color: #EA5707;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  color: #1F1F1F;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

ul, ol {
  padding-left: 20px;

  li {
    margin-bottom: 8px;
  }
}

h1 {
  font-family: "Inter", sans-serif;
  font-size: 55px;
  line-height: 1.5;
  //line-height: 62px;


}

h2 {
  font-family: "Inter", sans-serif;
  font-size: 39px;
  //font-size: 29px;
  line-height: 1.5;
}

h3 {
  font-family: "Inter", sans-serif;
  font-size: 29px;
  line-height: 1.5;
}

h4 {
  font-family: "Inter", sans-serif;
  font-size: 25px;
  line-height: 1.5;
}

h5 {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  line-height: 1.5;
}

h6 {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 1.5;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0 6%;
  box-sizing: border-box;

  @media screen and (min-width: $sm) {
    max-width: 540px;
    padding: 0;
  }

  @media screen and (min-width: $md) {
    max-width: 720px;
  }

  @media screen and (min-width: $lg) {
    max-width: 960px;
  }

  @media screen and (min-width: $xl) {
    max-width: 1160px;
  }
}

.call-to-action-wrapper {
  background-color: #f2f2f2;
  border-radius: 25px;
  padding: 26px 0;
  text-align: center;
}

.call-to-action {
  background-color: #EA5707FF;
  background-image: linear-gradient(90deg, #EA5707FF 0%, #EE7F04FF 100%);
  border-radius: 45px;
  color: #fff;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 20px;
  padding: 16px 0;
  text-transform: uppercase;
  width: 90%;

  @media screen and (min-width: $lg) {
    padding: 16px 158px;
    width: auto;
  }

  span:after {
    background-image: url("../images/icon-arrow-right.svg");
    background-position: 9px 5px;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: 24px;
    width: 35px;
  }

  &:hover {
    background-color: #EE7F04FF;
    background-image: linear-gradient(90deg, #EE7F04FF 0%, #EA5707FF 100%);
  }
}
